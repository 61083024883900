import React from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import toastr from 'toastr';

import http from '../../services/http.service';
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect';
import Layout from '../../components/Layout/Layout';
import InputWrapper from '../../components/UI/InputWrapper/InputWrapper';
import { Input, Textarea } from '../../components/UI/Input/Input';
import Label from '../../components/UI/Label/Label';
import Button from '../../components/UI/Button/Button';
import { CONTACT_FORM_FIELDS } from '../../constans/form-fields';
import { MESSAGE_INVALID, MESSAGE_REQUIRED, TOPIC_REQUIRED } from '../../constans/error-messages';
import { MESSAGE_CONTACT_MAX, REGEXP_NOSPACES } from '../../constans/validation';
import { emailValidationSchema } from '../../constans/validation-schemas';

import styles from './index.module.scss';

const initialValues = {
  topic: '',
  email: '',
  message: '',
};

const validationSchema = object().shape({
  topic: string().required(TOPIC_REQUIRED),
  message: string().required(MESSAGE_REQUIRED)
    .matches(REGEXP_NOSPACES, MESSAGE_INVALID)
    .max(MESSAGE_CONTACT_MAX, MESSAGE_INVALID),
}).concat(emailValidationSchema);

const Index = () => {
  const formSubmitHandler = (values, { resetForm }) => {
    http.post('/users/contact-us', { ...values })
      .then(() => {
        toastr.success('Your message is being processed.', 'Thanks for your email!');
        resetForm();
      });
  };

  return (
    <Layout mainClasses="section-padded-wrapper flex-grow-1">
      <div className={styles.container}>
        <div className={`${styles.title} h4-title`}>Contact</div>
        <Formik
          enableReinitialize
          onSubmit={formSubmitHandler}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={initialValues}
        >
          <Form>
            <InputWrapper>
              <Label>Topic</Label>
              <CustomSelect
                placeholder={CONTACT_FORM_FIELDS.contactTopic.placeholder}
                name={CONTACT_FORM_FIELDS.contactTopic.name}
                options={CONTACT_FORM_FIELDS.contactTopic.values}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Email</Label>
              <Input
                type="email"
                name={CONTACT_FORM_FIELDS.email.name}
                placeholder={CONTACT_FORM_FIELDS.email.placeHolder}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Message</Label>
              <Textarea
                maxLength={MESSAGE_CONTACT_MAX}
                name={CONTACT_FORM_FIELDS.message.name}
                placeholder={CONTACT_FORM_FIELDS.message.placeholder}
              />
            </InputWrapper>
            <Button
              type="submit"
              classes="btn btn_common btn_block t-600 btn-uppercased"
            >
              Send
            </Button>
          </Form>
        </Formik>
      </div>
    </Layout>
  );
};

export default Index;
